import React from 'react';
import styled from '../styled';
import { Lock, Bookmark } from 'react-feather';
import { Link } from 'gatsby';
import { COLORS } from '../themes/colors';

const COLOR = '#9da6b0';

const FeaturedTag = styled(Link)`
  display: inline-block;
  background-color: #432331;
  color: ${COLORS.secondary.light};
  font-weight: 700;
  font-size: 14px;
  padding: 6px 12px;
  border-radius: 8px;
  margin-bottom: 12px;
  margin-right: 6px;

  svg {
    top: 1px;
    position: relative;
  }

  &:hover {
    background-color: #712745;
  }
`;

const Tag = styled(Link)`
  display: inline-block;
  background-color: #252d37;
  color: ${COLOR};
  font-weight: 700;
  font-size: 14px;
  padding: 6px 12px;
  border-radius: 8px;
  margin-bottom: 12px;
  margin-right: 6px;

  &:hover {
    background-color: #394552;
    color: ${COLOR};
  }
`;

export const Tags = ({ tags = [] }: { tags?: string[] }) => {
  if (!tags || !tags.length) {
    return null;
  }

  return (
    <div>
      {tags.map((t) => (
        <div key={t} style={{ display: 'inline-block' }}>
          {t === 'paid' && (
            <FeaturedTag to={`/tags/${t}/`}>
              <Lock size={12} />
            </FeaturedTag>
          )}
          {t === 'featured' && (
            <FeaturedTag to={`/tags/${t}/`}>
              <Bookmark size={12} /> &nbsp;{t}
            </FeaturedTag>
          )}
          {t === 'interview' && <Tag to={`/interviews/`}>{t}</Tag>}
          {['featured', 'paid', 'interview'].indexOf(t) === -1 && (
            <Tag to={`/tags/${t}/`}>{t}</Tag>
          )}
        </div>
      ))}
    </div>
  );
};
