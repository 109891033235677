import React, { useState } from 'react';
import { Paper } from '@material-ui/core';
import { useCurrentUser } from '../services/currentUser';
import { SignupOrLogin } from '../components/LoginForm';
import styled from '../styled';
import { ENABLE_SIGNUP } from '../constants';

const noop = () => {};

const ProtectedContentPaper = styled(Paper)`
  padding: 32px 40px;
  max-width: 840px;
  margin: 0 auto;
`;

export const ProtectedContent: React.FC = ({ children }) => {
  const { loadingUser, isAuthenticated } = useCurrentUser();
  const [state, setState] = useState<'signup' | 'login'>(
    ENABLE_SIGNUP ? 'signup' : 'login'
  );

  const handleChange = (newValue: 'signup' | 'login') => {
    setState(newValue);
  };

  if (loadingUser) {
    return null;
  }

  if (isAuthenticated) {
    return <>{children}</>;
  }

  return (
    <ProtectedContentPaper>
      <SignupOrLogin
        initialState={state}
        onChangeState={handleChange}
        onLoginSuccess={noop}
        onSignupSuccess={noop}
      />
    </ProtectedContentPaper>
  );
};
