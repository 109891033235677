import { Link } from 'gatsby';
import React from 'react';
import { Button, Typography } from '@material-ui/core';
import styled from '../styled';
import { HighlightWhite } from './Highlight';

const FinalButton = styled(Button)`
  background: linear-gradient(86.61deg, #f4faff 23.95%, #dcefff 97.88%);
  color: ${(p) => p.theme.palette.grey[800]};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-weight: 600;
  padding: 12px 24px;
  transition: top 0.1s ease-in-out;
  position: relative;
  top: 0;

  &:hover {
    position: relative;
    top: -3px;
    background: linear-gradient(86.61deg, #f4faff 23.95%, #dcefff 97.88%);
    color: ${(p) => p.theme.palette.grey[800]};
  }
`;

const CommunityWrapper = styled('div')`
  background-color: #1890ff;
  color: white;
  overflow: hidden;
  margin-top: 5rem;
  padding: ${(p) => p.theme.spacing(6)}px ${(p) => p.theme.spacing(4)}px;
  position: relative;

  @media (max-width: 850px) {
    padding: 0;
  }
`;

const Img = styled('img')`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;

  @media (max-width: 850px) {
    display: none;
  }
`;

const ContentWrapper = styled('div')`
  max-width: 1150px;
  margin: 0 auto;
`;

const Content = styled('div')`
  width: 50%;
  padding: 16px;

  @media (max-width: 850px) {
    margin: 0;
    width: 100%;
  }
`;

const Title = styled('p')`
  font-size: ${(p) => p.theme.typography.h3.fontSize};
  margin-top: 0;
  line-height: 4rem;
  font-weight: bold;

  @media (max-width: 1000px) {
    font-size: ${(p) => p.theme.typography.h5.fontSize};
    line-height: 2rem;
  }
`;

export const CommunityFooter = () => {
  return (
    <CommunityWrapper>
      <Img
        src="/images/community-preview-footer.png"
        alt="Blogging for Devs Community"
      />
      <ContentWrapper>
        <Content>
          <Title>Grow your blog as part of a community.</Title>
          <Typography
            variant="body1"
            component="p"
            style={{ fontSize: '18px', marginBottom: '36px' }}
          >
            Become part of the{' '}
            <HighlightWhite>
              private community of over 300 developers
            </HighlightWhite>
            , indie hackers, freelancers, and tech creators growing their
            audience through writing and creating online.
          </Typography>
          <Link to="/pro/">
            <FinalButton
              size="large"
              style={{
                height: '56px',
                fontSize: '16px',
                minWidth: '200px'
              }}
              color="primary"
              variant="contained"
            >
              Join Our Community
            </FinalButton>
          </Link>
        </Content>
      </ContentWrapper>
    </CommunityWrapper>
  );
};
