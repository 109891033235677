import { Tooltip } from '@material-ui/core';
import { Facebook, Linkedin, Twitter } from 'react-feather';
import styled from '../styled';

const Pocket = require('../assets/pocket.svg') as React.ComponentType<any>;

const SocialWrapper = styled('div')`
  position: fixed;
  top: 300px;
  left: 13px;
  z-index: 10;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 900px) {
    top: auto;
    bottom: 0;
    opacity: 1;
    background-color: ${(p) => p.theme.palette.grey[900]};
    left: 0;
    width: 100%;
    display: flex;
    padding: 12px 0 0 12px;
    justify-content: flex-end;
    align-items: center;

    &:before {
      content: 'Share this post';
      position: absolute;
      left: 18px;
      bottom: 18px;
    }

    a {
      display: inline-block;
      margin-right: 12px;
    }
  }
`;

type IconProps = {
  color?: string;
  size?: string | number;
};

const SocialIcon = styled('a')`
  display: block;
  font-size: 12px;
  transition: all ease-out 0.15s;
  margin: 0 0 ${(p) => p.theme.spacing(1)}px;
  color: white;
  padding: ${(p) => p.theme.spacing(1)}px;
  width: 36px;
  height: 36px;
  border-radius: ${(p) => p.theme.custom.borderRadius.unit / 2}px;

  &:hover {
    transform: scale(1.25);
  }
`;

const HackerNewsY = styled('div')`
  color: white;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
`;

const HackerNewsButton = ({
  color,
  tooltip,
  ...props
}: {
  color: string;
  tooltip: string;
} & React.HTMLProps<HTMLAnchorElement>) => (
  <Tooltip title={tooltip} placement="right">
    <SocialIcon href="#" style={{ backgroundColor: color }} {...props}>
      <HackerNewsY>Y</HackerNewsY>
    </SocialIcon>
  </Tooltip>
);

const PocketButton = ({
  color,
  tooltip,
  icon: IconComponent,
  ...props
}: {
  color: string;
  icon: React.ReactNode;
  tooltip: string;
} & React.HTMLProps<HTMLAnchorElement>) => (
  <Tooltip title={tooltip} placement="right">
    <SocialIcon
      href="#"
      style={{ backgroundColor: color, color: 'white' }}
      {...props}
    >
      {IconComponent}
    </SocialIcon>
  </Tooltip>
);

const PocketSvg = styled(Pocket)`
  width: 20px;
  height: 20px;

  path {
    fill: white;
  }
`;

const SocialShareButton = ({
  color,
  tooltip,
  icon: IconComponent,
  ...props
}: {
  color: string;
  icon: React.ComponentType<IconProps>;
  tooltip: string;
} & React.HTMLProps<HTMLAnchorElement>) => (
  <Tooltip title={tooltip} placement="right">
    <SocialIcon href="#" style={{ backgroundColor: color }} {...props}>
      <IconComponent size={20} />
    </SocialIcon>
  </Tooltip>
);

export const SocialSharingButtons = ({
  slug,
  title,
  tweet
}: {
  slug: string;
  title: string;
  tweet: string;
}) => {
  const postUrl = encodeURIComponent('https://bloggingfordevs.com/' + slug);
  const tweetText = encodeURIComponent(tweet + ' via @bloggingfordevs');
  const twitterHref = `https://twitter.com/share?text=${tweetText}&url=${postUrl}`;
  const facebookHref = `https://www.facebook.com/sharer/sharer.php?u=${postUrl}`;
  const linkedInHref = `https://www.linkedin.com/sharing/share-offsite/?url=${postUrl}`;
  const hackerNewsHref = `https://news.ycombinator.com/submitlink?u=${postUrl}&t=${title}`;
  const pocketHref = `https://getpocket.com/edit?url=${postUrl}&title=${title}`;

  return (
    <SocialWrapper>
      <SocialShareButton
        color="#3c5a98"
        tooltip="Share on Facebook"
        href={facebookHref}
        icon={Facebook}
        onClick={(e: React.SyntheticEvent) => {
          e.preventDefault();
          window.open(facebookHref, 'facebook-share', 'width=550,height=235');
        }}
      />
      <SocialShareButton
        color="#3ea9dd"
        tooltip="Tweet this"
        icon={Twitter}
        onClick={(e: React.SyntheticEvent) => {
          e.preventDefault();
          window.open(twitterHref, 'twitter-share', 'width=550,height=235');
        }}
        href={twitterHref}
      />
      <SocialShareButton
        color="#0077B5"
        tooltip="Share on LinkedIn"
        icon={Linkedin}
        onClick={(e: React.SyntheticEvent) => {
          e.preventDefault();
          window.open(linkedInHref, 'linkedin-share', 'width=550,height=235');
        }}
        href={linkedInHref}
      />
      <PocketButton
        color="#f5222d"
        tooltip="Save to Pocket"
        href={pocketHref}
        icon={<PocketSvg />}
        onClick={(e: React.SyntheticEvent) => {
          e.preventDefault();
          window.open(pocketHref, 'pocket-share', 'width=550,height=235');
        }}
      />
      <HackerNewsButton
        color="#fa8c16"
        tooltip="Share on Hacker News"
        onClick={(e: React.SyntheticEvent) => {
          e.preventDefault();
          window.open(
            hackerNewsHref,
            'hacker-news-share',
            'width=550,height=350'
          );
        }}
        href={hackerNewsHref}
      />
    </SocialWrapper>
  );
};
