import { Typography } from '@material-ui/core';
import { MDXProvider } from '@mdx-js/react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import { AuthorImage, AuthorName, AuthorWrapper } from '../components/Author';
import { Blockquote } from '../components/Blockquote';
import { Branding } from '../components/Branding';
import { ClientSideOnly } from '../components/ClientSidePage';
import { Comments } from '../components/Comments';
import { Highlight } from '../components/Highlight';
import { PlainLoader } from '../components/Loader';
import { ProductHuntBanner } from '../components/ProductHuntBanner';
import { ProtectedContent } from '../components/ProtectedContent';
import { SEO } from '../components/SEO';
import { Tags } from '../components/Tags';
import Layout, { Wrapper } from '../layouts/Layout';
import { useCommentContainer } from '../services/comments';
import { useCurrentUser } from '../services/currentUser';
import styled from '../styled';
import { MdxArticle } from '../types/Article';
import { SiteMetadata } from '../types/SiteMetadata';
import { SocialSharingButtons } from '../components/SocialSharingButtons';
import { CommunityFooter } from '../components/CommunityFooter';

type IAuthor = {
  name: string;
  twitter: string;
  image: string;
};

interface IAuthorHash {
  [key: string]: IAuthor;
}

const AUTHORS: IAuthorHash = {
  monica: {
    name: 'Monica Lent',
    twitter: 'monicalent',
    image: '/images/my-face.jpg'
  }
};

type PageQueryData = {
  mdx: MdxArticle;
  site: {
    siteMetadata: SiteMetadata;
  };
  allMdx: {
    edges: {
      node: MdxArticle;
    }[];
  };
};

export const pageQuery = graphql`
  query($postId: String) {
    mdx(id: { eq: $postId }) {
      id
      body
      fields {
        slug
      }
      frontmatter {
        title
        date
        author
        commentId
        summary
        image
        tags
        socialSharingImage
        seoTitle
        seoDescription
        tweet
      }
    }
  }
`;

const COLOR = '#69c0ff';
const BODY_COPY = '#a7b3c1';

const Title = styled('h1')`
  color: ${COLOR};
  font-size: 36px;
  text-align: center;
`;

const COPY_FONT_SIZE = '20px';
const COPY_LINE_HEIGHT = '40px';

const A = styled('a')`
  border-bottom: 2px solid ${COLOR};
  color: ${COLOR};
  transition: 0.1s border-bottom linear;
  font-size: ${COPY_FONT_SIZE};
  line-height: ${COPY_LINE_HEIGHT};

  &:hover {
    border-bottom: 4px solid ${COLOR};
  }

  p,
  ol,
  li & {
    font-size: ${COPY_FONT_SIZE};
    line-height: ${COPY_LINE_HEIGHT};
  }
`;

const Ol = styled('ol')`
  font-size: ${COPY_FONT_SIZE};
  line-height: ${COPY_LINE_HEIGHT};
  color: ${BODY_COPY};

  strong {
    font-weight: 700;
    color: #fff;
  }

  li code {
    display: inline-block;
    font-family: Consolas, Menlo, Courier, monospace;
    background-color: #101010;
    border: 1px solid #444;
    color: ${BODY_COPY};
    padding: 6px 8px;
    border-radius: 8px;
    line-height: 1em;
    font-size: 0.9em;
  }

  a {
    border-bottom: 2px solid ${COLOR};
    color: ${COLOR};
    transition: 0.1s border-bottom linear;
    font-size: ${COPY_FONT_SIZE};
    line-height: ${COPY_LINE_HEIGHT};

    &:hover {
      border-bottom: 4px solid ${COLOR};
    }
  }
`;

const Ul = styled('ul')`
  font-size: ${COPY_FONT_SIZE};
  line-height: ${COPY_LINE_HEIGHT};
  color: ${BODY_COPY};

  li code {
    display: inline-block;
    font-family: Consolas, Menlo, Courier, monospace;
    background-color: #101010;
    border: 1px solid #444;
    color: ${BODY_COPY};
    padding: 6px 8px;
    border-radius: 8px;
    line-height: 1em;
    font-size: 0.9em;
  }

  strong {
    font-weight: 700;
    color: #fff;
  }

  a {
    border-bottom: 2px solid ${COLOR};
    color: ${COLOR};
    transition: 0.1s border-bottom linear;
    font-size: ${COPY_FONT_SIZE};
    line-height: ${COPY_LINE_HEIGHT};

    &:hover {
      border-bottom: 4px solid ${COLOR};
    }
  }
`;

const Code = styled('code')`
  display: inline-block;
  font-family: Consolas, Menlo, Courier, monospace;
  background-color: #101010;
  border: 1px solid #444;
  color: ${BODY_COPY};
  padding: 12px 24px;
  border-radius: 8px;
  width: 100%;
  overflow-x: auto;
  margin: 24px 0;
`;

const P = styled(Typography)`
  font-size: ${COPY_FONT_SIZE};
  line-height: ${COPY_LINE_HEIGHT};
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
  color: ${BODY_COPY};

  strong {
    font-weight: 700;
    color: #fff;
  }

  a {
    border-bottom: 2px solid ${COLOR};
    color: ${COLOR};
    transition: 0.1s border-bottom linear;
    font-size: ${COPY_FONT_SIZE};
    line-height: ${COPY_LINE_HEIGHT};

    &:hover {
      border-bottom: 4px solid ${COLOR};
    }
  }

  .callout {
    background-color: #333;
  }

  code {
    display: inline-block;
    font-family: Consolas, Menlo, Courier, monospace;
    background-color: #101010;
    border: 1px solid #444;
    color: ${BODY_COPY};
    padding: 6px 8px;
    border-radius: 8px;
    line-height: 1em;
    font-size: 0.9em;
  }
`;

const Img = styled('img')`
  margin-top: ${(p) => p.theme.spacing(2)}px;
  margin-bottom: ${(p) => p.theme.spacing(4)}px;
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
`;

const Hr = styled('hr')`
  border: 1px solid #2d3239;
  margin: 36px auto;
  max-width: 50%;
`;

const H1 = styled('h1')`
  margin-top: ${(p) => p.theme.spacing(6)}px;
`;

const H2 = styled('h2')`
  margin-top: ${(p) => p.theme.spacing(6)}px;
  font-size: 28px;
`;

const H3 = styled('h3')`
  margin-top: ${(p) => p.theme.spacing(6)}px;
  font-size: 22px;
`;

const H4 = styled('h4')`
  margin-top: ${(p) => p.theme.spacing(6)}px;
  font-size: 20px;
`;

const Table = styled('table')`
  margin-bottom: ${(p) => p.theme.spacing(3)}px;
`;

const mdxComponents = {
  a: A,
  hr: Hr,
  p: (props: any) => <P variant="h3" component="p" {...props} />,
  blockquote: Blockquote,
  img: Img,
  ol: Ol,
  table: Table,
  ul: Ul,
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  mark: Highlight,
  code: Code
};

const Author = ({ name, date }: { date: string; name: string }) => {
  const author = AUTHORS[name];

  if (!author) {
    return null;
  }

  return (
    <AuthorWrapper>
      <AuthorImage src={author.image} />
      <AuthorName>
        <a
          href={`https://twitter.com/${author.twitter}`}
          target="_blank"
          rel="noopener"
        >
          {author.name}
        </a>{' '}
        published <time dateTime={date}>{formatDate(date)}</time>
      </AuthorName>
    </AuthorWrapper>
  );
};

const formatDate = (date: string) => {
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

const BlogPostComments = ({
  postId,
  href,
  title
}: {
  postId: string;
  href: string;
  title: string;
}) => {
  const [d, loading] = useCommentContainer(postId, {
    href,
    title: `Blog Post: ${title}`
  });
  return <Comments d={d} loading={loading} />;
};

export default function Template({ data }: { data: PageQueryData }) {
  const { mdx } = data;
  const { frontmatter, fields } = mdx;
  const { tags } = frontmatter;

  const isPremium = tags.includes('paid');
  const { isAuthenticated } = useCurrentUser();

  const loading = typeof window === 'undefined';
  const shouldShowContent = !isPremium || isAuthenticated;

  return (
    <Layout>
      <ProductHuntBanner />
      <SEO
        siteUrl="https://bloggingfordevs.com"
        title={frontmatter.seoTitle || frontmatter.title}
        description={frontmatter.seoDescription || frontmatter.summary}
        image={frontmatter.socialSharingImage}
        pathname={fields.slug.substr(1, fields.slug.length)}
        isArticle={true}
        publishedDate={frontmatter.date}
        noIndex={tags.includes('paid')}
      />
      <Wrapper style={{ padding: '12px' }}>
        <SocialSharingButtons
          slug={mdx.fields.slug}
          title={mdx.frontmatter.title}
          tweet={mdx.frontmatter.tweet}
        />
        <Branding to="/">Blogging for Devs</Branding>
        <div style={{ textAlign: 'center', marginBottom: '24px' }}>
          <Tags tags={frontmatter.tags} />
        </div>
        <Title>{frontmatter.title}</Title>
        <Author name={frontmatter.author} date={frontmatter.date} />
        {!shouldShowContent && !loading && (
          <>
            <Typography
              variant="h6"
              component="h1"
              gutterBottom
              style={{
                textAlign: 'center',
                maxWidth: '500px',
                margin: '48px auto 48px'
              }}
            >
              This article is only available for Paid Members.
              <br />
              Join our community for access.
            </Typography>
          </>
        )}
        {!shouldShowContent && !loading ? (
          <ProtectedContent>
            <div style={{ marginBottom: '48px' }}>
              <MDXProvider components={mdxComponents}>
                <MDXRenderer>{mdx.body}</MDXRenderer>
              </MDXProvider>
            </div>
          </ProtectedContent>
        ) : (
          <div style={{ marginBottom: '48px' }}>
            <MDXProvider components={mdxComponents}>
              <MDXRenderer>{mdx.body}</MDXRenderer>
            </MDXProvider>
          </div>
        )}
        {shouldShowContent && (
          <ClientSideOnly fallback={<PlainLoader height={400} />}>
            <BlogPostComments
              postId={frontmatter.commentId}
              href={`/${fields.slug}`}
              title={frontmatter.title}
            />
          </ClientSideOnly>
        )}
      </Wrapper>
      <CommunityFooter />
    </Layout>
  );
}
