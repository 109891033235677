import { Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useCurrentUser } from '../../services/currentUser';
import styled from '../../styled';
import { ICommentContainer } from '../../types/Comment';
import { Doc, DocReference } from '../../types/Document';
import { PlainLoader } from '../Loader';
import { CommentFeed } from './CommentFeed';
import { NewRootCommentBox } from './NewRootCommentBox';

const Header = styled('div')`
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
`;

const Section = styled('div')`
  margin-bottom: ${(p) => p.theme.spacing(4)}px;
`;

export const Comments = React.memo(
  ({ d, loading }: { d: void | Doc<ICommentContainer>; loading: boolean }) => {
    const { user, loadingUser } = useCurrentUser();
    const ref: DocReference | null = useMemo(
      () => (d ? { collection: d.collection, id: d.id } : null),
      [d]
    );
    return (
      <div>
        <Header>
          <Typography component="h1" variant="h5">
            Discussion
          </Typography>
        </Header>
        {(loading || loadingUser) && <PlainLoader height={400} />}
        {!loading && !loadingUser && ref && (
          <>
            <Section>
              <NewRootCommentBox entityRef={ref} user={user} />
            </Section>
            <Section>
              <CommentFeed entityRef={ref} />
            </Section>
          </>
        )}
      </div>
    );
  }
);
