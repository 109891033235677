import { Button } from '@material-ui/core';
import { navigate } from 'gatsby';
import React, { useState } from 'react';
import { useDialogState } from '../../hooks/useDialogState';
import { toCommentIdHash } from '../../services/comments';
import { Doc, DocReference } from '../../services/document';
import styled from '../../styled';
import { IComment } from '../../types/Comment';
import { CurrentUser } from '../../types/User';
import { SignupOrLoginDialog } from '../LoginForm';
import { GuestCommentForm, UserCommentForm } from './CommentForm';

type Props = {
  entityRef: DocReference;
  user: CurrentUser | null;
};

const Box = styled('div')((p) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: p.theme.spacing(4),
  paddingBottom: p.theme.spacing(3),
  background: p.theme.palette.grey[700],
  border: `1px solid ${p.theme.palette.grey[400]}`,
  borderRadius: p.theme.custom.borderRadius.unit,

  '> :not(:first-child)': {
    marginTop: p.theme.spacing()
  }
}));

// states
// - logged in -> Logged in comment form
// - not logged in
//   -> Comment as a member -> login/signup
//   -> Comment as a guest -> Guest comment form

export const NewRootCommentBox: React.FC<Props> = ({ entityRef, user }) => {
  const [guestEditMode, setGuestEditMode] = useState(true);
  const { dialogOpen, openDialog, closeDialog } = useDialogState(false);
  const onSuccess = (d: Doc<IComment>) =>
    navigate(`${window.location.pathname}/#${toCommentIdHash(d.id)}`);

  if (user) {
    return (
      <UserCommentForm
        user={user}
        entityRef={entityRef}
        onSuccess={onSuccess}
      />
    );
  }

  if (guestEditMode) {
    return <GuestCommentForm entityRef={entityRef} onSuccess={onSuccess} />;
  }

  return (
    <Box>
      <Button variant="contained" color="primary" onClick={openDialog}>
        Comment as a Community Member
      </Button>
      <Button onClick={() => setGuestEditMode(true)}>
        Or, comment as a guest
      </Button>
      <SignupOrLoginDialog
        open={dialogOpen}
        onClose={closeDialog}
        initialState="login"
      />
    </Box>
  );
};
