import styled from '../styled';

export const AuthorWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${(p) => p.theme.spacing(2)}px auto ${(p) => p.theme.spacing(10)}px;
  color: #e3e9f0;

  a {
    border-bottom: 2px solid;

    &:hover {
      border-bottom: 4px solid;
    }
  }
`;

export const AuthorImage = styled('img')`
  width: 30px;
  border-radius: 100%;
  border: 2px solid white;
  margin-right: ${(p) => p.theme.spacing(1)}px;
  display: inline-block;
`;

export const AuthorName = styled('p')`
  font-size: 18px;
  white-space: nowrap;
`;
